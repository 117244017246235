import { render, staticRenderFns } from "./GeneralAnaesthesia.vue?vue&type=template&id=4e9e081c&scoped=true"
import script from "./GeneralAnaesthesia.vue?vue&type=script&lang=js"
export * from "./GeneralAnaesthesia.vue?vue&type=script&lang=js"
import style0 from "./GeneralAnaesthesia.vue?vue&type=style&index=0&id=4e9e081c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e9e081c",
  null
  
)

/* custom blocks */
import block0 from "@/locales/general_anaesthesia.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Fgeneral-anaesthesia%2FGeneralAnaesthesia.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-implant%2Fgeneral-anaesthesia%2FGeneralAnaesthesia.vue&external"
if (typeof block1 === 'function') block1(component)

export default component.exports